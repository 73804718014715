import {
	createApp
} from 'vue'
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // import styles
import 'quill/dist/quill.snow.css' // for snow theme
import 'quill/dist/quill.bubble.css' // for bubble theme
import App from './App.vue'

import qs from 'qs'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
// import pjsua from 'pjsip-js';

import 'element-plus/lib/theme-chalk/index.css';

import './assets/css/icon.css'
import AMapLoader from "@amap/amap-jsapi-loader";

import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
import Plugin from 'v-fit-columns';
const app = createApp(App).use(router).use(store).use(qs).use(VueQuillEditor).use(AMapLoader).use(vueMiniPlayer).use(
	Plugin)
installElementPlus(app),
	app.mount('#app')