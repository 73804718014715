<template>
	<div class="about">
		<v-header />
		<v-sidebar />
		<div class="content-box" :class="{ 'content-collapse': collapse }"
			style="border-left:10px solid #f0f0f0;border-top: 10px solid #f0f0f0;">
			<v-tags></v-tags>
			<div class="content">
				<router-view v-slot="{ Component }">
					<transition name="move" mode="out-in">
						<keep-alive :include="tagsList">
							<component :is="Component" />
						</keep-alive>
					</transition>
				</router-view>
			</div>
		</div>
	</div>
</template>
<script>
	import vHeader from '../components/Header';
	import vSidebar from '../components/Sidebar';
	import vTags from '../components/Tags.vue';
	export default {
		components: {
			vHeader,
			vSidebar,
			vTags
		},
		computed: {
			tagsList() {
				return this.$store.state.tagsList.map(item => item.name);
			},
			collapse() {
				return this.$store.state.collapse;
			}
		}
	};
</script>