import request from '../utils/request';
import qs from "qs";

export const fetchData = param => {
	return request.post('/api/user/list', qs.stringify(param))
};
export const fetchUserById = userId => {
	return request.get('/api/user/fetchUserById?userId=' + userId)
};
export const addUser = param => {
	return request.post('/api/user/add', qs.stringify(param))
};

export const updateUser = param => {
	return request.post('/api/user/update', qs.stringify(param))
};

export const setRoleAndValidDate = param => {
	return request.post('/api/user/setRoleAndValidDate', qs.stringify(param))
};

export const delUser = data => {
	return request.get('/api/user/delete?userId=' + data.id + '&&currentUserId=' + data.currentUserId)
};

export const modifyPassword = param => {
	return request.post('/api/user/modifyPassword', qs.stringify(param))
};


export const batchDelete = userIds => {
	return request.post('/api/user/batchDelete', qs.stringify(userIds))
};
export const batchUpdate = param => {
	return request.post('/api/user/batchUpdate', qs.stringify(param))
};
export const riskCount = param => {
	return request.post('/api/user/riskCount', qs.stringify(param))
};


export const downloadUsers = param => {
	return request.post('/api/user/downloadUsers', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const getRiskCount = param => {
	return request.post('/api/user/getRiskCount', qs.stringify(param))
};
export const blackListDelete = param => {
	return request.post('/api/blackList/delete', qs.stringify(param))
};
export const getbulkAdd = param => {
	return request.post('/api/blackList/bulkAdd', qs.stringify(param))
};

export const batchQueryStaff = param => {
	return request.post('/api/user/batchQueryStaff', qs.stringify(param))
};

export const batchDeleteByUserPhones = param => {
	return request.post('/api/user/batchDeleteByUserPhones', qs.stringify(param))
};
export const transferStaff = param => {
	return request.post('/api/user/transferStaff', qs.stringify(param))
};
export const openServices = param => {
	return request.post('/api/user/openServices', qs.stringify(param))
};
export const userBatchQuery = param => {
	return request.post('/api/dxt/userBatchQuery', qs.stringify(param))
};

export const snailUserBatchQuery = param => {
	return request.post('/api/Snail/userBatchQuery', qs.stringify(param))
};

export const xsAxbuserList = param => {
	return request.post('/api/xsAxb/userList', qs.stringify(param))
};


export const getBlackList = param => {
	return request.get('/api/blackList/list?userId=' + param.userId + '&&agentId=' + param.agentId +
		'&&companyId=' + param.companyId + '&&phoneNumber=' + param.phoneNumber + '&&pageIndex=' + param
		.pageIndex + '&&pageSize=' + param.pageSize + '&&means=' + param.means, )
};