import request from '../utils/request';
import qs from "qs";

export const getHomePageInfoWithUser = param => {
	return request.post('/api/sum/getHomePageInfoWithUser', qs.stringify(param))
};

export const getLeaderboard = param => {
	return request.post('/api/sum/leaderboard', qs.stringify(param))
};

export const getMonthInfo = data => {
	return request.get('/api/sum/getMonthInfo?currentUserId=' + data.currentUserId)
};
export const webGetHeadPortrait = data => {
	return request.get('/api/user/webGetHeadPortrait?currentUserId=' + data.currentUserId)
};
export const getFindAgent = data => {
	return request.get('/api/warning/findAgent?currentUserId=' + data.currentUserId)
};
export const getUserInfo = data => {
	return request.get('/api/warning/top?currentUserId=' + data.currentUserId)
};
export const getWarNingPhone = param => {
	return request.post('/api/warning/warNingPhone', qs.stringify(param))
};
export const getCallData = param => {
	return request.post('/api/sum/getCallData', qs.stringify(param))
};

export const getWarNingCompany = param => {
	return request.post('/api/warning/warNingCompany', qs.stringify(param))
};

export const getYiXiangData = param => {
	return request.post('/api/warning/count', qs.stringify(param))
};

export const getCompanyStatistics = param => {
	return request.post('/api/sum/companyStatistics', qs.stringify(param))
};
export const updateCompanyStatistics = param => {
	return request.post('/api/sum/updateCompanyStatistics', qs.stringify(param))
};

export const downLoadWarningPhone = param => {
	return request.post('/api/warning/downloadWarningPhone', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const downloadInvalidDatePhone = param => {
	return request.post('/api/warning/downloadInvalidDatePhone', qs.stringify(param), {
		responseType: 'blob'
	})
};
// eslint-disable-next-line no-unused-vars
export const download = param => {
	return request.post('/api/sum/download', qs.stringify(param), {
		responseType: 'blob'
	})
};

export const getConnection = data => {
	return request.get('/api/socket/web/getConnection?name=' + data.name)
};
//录音率
export const recordingRatio = param => {
	return request.post('/api/record/recordingRatio', qs.stringify(param))
};

//停用录音账号
export const recordingClose = param => {
	return request.post('/api/record/close', qs.stringify(param))
};
//蜗牛充值查询待处理订单 
export const getcount = param => {
	return request.post('/api/topUp/count', qs.stringify(param))
};
