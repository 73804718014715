import request from '../utils/request';
import qs from "qs";
// eslint-disable-next-line no-unused-vars
export const fetchCompany = currentUserId => {
	return request.get('/api/company/all?currentUserId=' + currentUserId)
};

//发送验证码 
export const sendCode = param => {
	return request.post('/api/login/sendCode', qs.stringify(param))
};
// eslint-disable-next-line no-unused-vars
export const fetchDepartment = data => {
	return request.get('/api/department/all?companyId=' + data.companyId + '&&currentUserId=' + data.currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchUsers = userId => {
	return request.get('/api/user/users')
};

export const fetchUsersByCompanyId = companyId => {
	return request.get('/api/user/fetchUsersByCompanyId?companyId=' + companyId)
};

export const fetchUsersByDepartmentId = data => {
	return request.get('/api/user/fetchUsersByDepartmentId?departmentId=' + data.departmentId + '&&currentUserId=' +
		data.currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchDefaultUsers = currentUserId => {
	return request.get('/api/user/fetchDefaultUsers?currentUserId=' + currentUserId)
};

// 获取登录的网址
export const getOemInfo = url => {
	return request.get('/api/oem/getOemInfo?url=' + url)
};


export const getOperationRecord = param => {
	return request.post('/api/user/getOperationRecord', qs.stringify(param))
};
export const refreshToken = param => {
	return request.post('/api/login/refreshToken', qs.stringify(param))
};
export const getloginRecord = param => {
	return request.post('/api/login/loginRecord', qs.stringify(param))
};
export const queryRequest = param => {
	return request.post('/api/record/queryRequest', qs.stringify(param))
};
export const addressErrList = param => {
	return request.post('/api/user/addressErrList', qs.stringify(param))
};
export const maximumRecordList = param => {
	return request.post('/api/record/maximumRecord', qs.stringify(param))
};
export const riskRecord = param => {
	return request.post('/api/record/riskRecord', qs.stringify(param))
};
export const completeToday = param => {
	return request.post('/api/record/completeToday', qs.stringify(param))
};
export const riskList = param => {
	return request.post('/api/record/riskList', qs.stringify(param))
};
export const riskRecordUpdate = param => {
	return request.post('/api/record/riskRecordUpdate', qs.stringify(param))
};
export const qualityRecord = param => {
	return request.post('/api/record/qualityRecord', qs.stringify(param))
};
export const AIInspectionList = param => {
	return request.post('/api/record/AIInspectionList', qs.stringify(param))
};
export const cycjErrRecord = param => {
	return request.post('/api/record/cycjErrRecord', qs.stringify(param))
};
export const QualityCompany = userId => {
	return request.get('/api/record/QualityCompany?userId=' + userId)
};

export const getAgentMarkerPrice = param => {
	return request.get('/api/markers/getAgentMarkerPrice')
};
export const agentOptionList = userId => {
	return request.get('/api/markers/agentOption?userId=' + userId)
};

export const deleteOption = param => {
	return request.post('/api/markers/deleteOption', qs.stringify(param))
};
export const addQuery = param => {
	return request.post('/api/markers/addQuery', qs.stringify(param))
};
export const getrecord = param => {
	return request.post('/api/markers/record', qs.stringify(param))
};
export const topup = param => {
	return request.post('/api/markers/topup', qs.stringify(param))
};
//标记查询 admin添加次数
export const adminAdd = param => {
	return request.post('/api/markers/adminAdd', qs.stringify(param))
};
//标记查询导出
export const exportMarkers = param => {
	return request.post('/api/markers/export', qs.stringify(param), {
		responseType: 'blob'
	})
};
export const setagentMarkerPrice = param => {
	return request.post('/api/markers/agentMarkerPrice', qs.stringify(param))
};
export const agentMarkerOption = param => {
	return request.post('/api/markers/agentMarkerOption', qs.stringify(param))
};
export const AiRiskRecord = param => {
	return request.post('/api/record/AiRiskRecord', qs.stringify(param))
};

export const getriskTel = param => {
	return request.post('/api/blackList/riskTel', qs.stringify(param))
};

export const AbnormalCallCheck = param => {
	return request.post('/api/record/AbnormalCallCheck', qs.stringify(param))
};
export const webClue = param => {
	return request.post('/api/markers/webClue', qs.stringify(param))
};
export const openControl = param => {
	return request.post('/api/user/openControl', qs.stringify(param))
};
export const openControlList = param => {
	return request.post('/api/user/openControlList', qs.stringify(param))
};
export const openControlDelete = param => {
	return request.post('/api/user/openControlDelete', qs.stringify(param))
};

//光速AXB用户信息查询

export const xsAxbPhoneInfo = data => {
	return request.get('/api/xsAxb/phoneInfo?phone=' + data.phone)
};
//长江异常导出
export const exportCJErrRecord = param => {
	return request.post('/api/record/exportCJErrRecord', qs.stringify(param), {
		responseType: 'blob'
	})
};